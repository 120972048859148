﻿let convertString = function() {
  let stepCounter = 1;
  let inputString = document.getElementById("dtmf-string").value;
  let totalTime = 0;
  for (let i = 0; i < inputString.length; i++) {
      if (inputString[i] === 'w') {
          let count = 0;
          while (inputString[i] === 'w') {
              count++;
              i++;
          }
          i--; // Decrement i since it is incremented in the for loop
          let seconds = count / 2;
          totalTime += seconds;
      } else if (inputString[i].match(/[0-9*#]/)) {
          stepCounter++;
      }
  }
}

$(function () {
  const speakerDevices = document.getElementById("speaker-devices");
  const ringtoneDevices = document.getElementById("ringtone-devices");
  const outputVolumeBar = document.getElementById("output-volume");
  const inputVolumeBar = document.getElementById("input-volume");
  const volumeIndicators = document.getElementById("volume-indicators");
  const callButton = document.getElementById("button-call");
  const outgoingCallHangupButton = document.getElementById("hangup");
  const callControlsDiv = document.getElementById("call-controls");
  const audioSelectionDiv = document.getElementById("output-selection");
  const getAudioDevicesButton = document.getElementById("get-devices");
  const logDiv = document.getElementById("log");
  const incomingCallDiv = document.getElementById("incoming-call");
  const incomingCallHangupButton = document.getElementById(
    "button-hangup-incoming"
  );
  const incomingCallAcceptButton = document.getElementById(
    "button-accept-incoming"
  );
  const incomingCallRejectButton = document.getElementById(
    "button-reject-incoming"
  );
  const phoneNumberInput = document.getElementById("phone-number");
  const incomingPhoneNumberEl = document.getElementById("incoming-number");
  const startupButton = document.getElementById("startup-button");
  const clearHistoryButton = document.getElementById('clear-history-button');

  let device;
  let token;
  let callStarted=false;
  let call;  // Declare call object globally
  let callStartTime;  // Declare variable for call start time
  let dtmfDigits = "";
  let phoneNumber = ""; // to store the dialed number
  let callTimer; 
  let seconds = 0, minutes = 0, hours = 0;
  let isPasting = false;
  let lastTime;
  let contactList = JSON.parse(localStorage.getItem('contactList')) || [];
  let callActive = true; // We check if a call is still active before sending DTMF tones
  let testCall = false; // ensure that the recordCallHistory function call is only executed for regular outgoing calls, and not when a test call is made


  // Event Listeners

  callButton.onclick = (e) => {
    e.preventDefault(); 
    callButton.classList.add("hide");
    outgoingCallHangupButton.classList.remove("hide");
  
    var dtmfString = document.getElementById("dtmf-string").value;
  
    makeOutgoingCall(phoneNumberInput.value).then((call) => { 
      call.on("accept", () => {
        if (dtmfString) {
          playDtmfString(dtmfString, call);
        }
      });
    });
  };  
  
  $('.fa-long-arrow-left').on('click', function() {
    var input = document.getElementById("phone-number");
    var current = input.value;
    var newVal = current.slice(0, -1);
    input.value = newVal;
  }); 

  getAudioDevicesButton.onclick = getAudioDevices;
  speakerDevices.addEventListener("change", updateOutputDevice);
  ringtoneDevices.addEventListener("change", updateRingtoneDevice);

  clearHistoryButton.addEventListener('click', function() {
    // Clear the call history
    var callHistory = [];
    
    // Clear the call history in the UI
    document.getElementById('call-history').innerHTML = '';
    
    // Save the updated history back to local storage.
    localStorage.setItem('callHistory', JSON.stringify(callHistory));
  });

  document.addEventListener('keypress', function(e) {
    var char = String.fromCharCode(e.which);
    var activeElementId = document.activeElement.id;
  
    if (activeElementId === 'dtmf-string') {
      // For DTMF field, allow digits without any modifications
      dtmfString += char;
      document.getElementById("dtmf-string").value = dtmfString;
    } else if (!isNaN(char) && activeElementId === 'test-call-phone-number') {
      // For phone number field, only allow digits
      e.preventDefault();  // Prevent the browser's default behavior
      testPhoneNumber += char;
      document.getElementById("test-call-phone-number").value = testPhoneNumber;
    }
  });        
  
  document.getElementById("phone-number").addEventListener('paste', function(e) {
    // Prevent the default paste action
    e.preventDefault();
  
    isPasting = true;
  
    var text = (e.clipboardData || window.clipboardData).getData('text');
    var cleanedText = text.replace(/\D/g, '');
  
    if (!isNaN(cleanedText)) {
      phoneNumber = cleanedText;
      document.getElementById("phone-number").value = phoneNumber;
    }
  
    isPasting = false;
  });  
    

  // SETUP STEP 1:
  // Browser client should be started after a user gesture
  // to avoid errors in the browser console re: AudioContext
  startupButton.addEventListener("click", startupClient);

  // SETUP STEP 2: Request an Access Token
  async function startupClient() {
    log("Requesting Access Token...");

    try {
      const data = await $.getJSON("/token");
      log("Got a token.");
      token = data.token;
      console.log("Token: " + token);
      setClientNameUI(data.identity);
      intitializeDevice();
    } catch (err) {
      console.log(err);
      log("An error occurred. See your browser console for more information.");
    }
  }

  // SETUP STEP 3:
  // Instantiate a new Twilio.Device
  function intitializeDevice() {
    logDiv.classList.remove("hide");
    log("Initializing device");
    device = new Twilio.Device(token, {
      logLevel:0,
      // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
      // providing better audio quality in restrained network conditions.
      codecPreferences: ["opus", "pcmu"],

      //allow incoming calls while busy
      allowIncomingWhileBusy: true,
    });

    addDeviceListeners(device);

    // Device must be registered in order to receive incoming calls
    device.register();
    // Display the call history
    displayCallHistory();
  }

  // SETUP STEP 4:
  // Listen for Twilio.Device states
  function addDeviceListeners(device) {
    device.on("registered", function () {
      log("Twilio.Device Ready to make and receive calls!");
      callControlsDiv.classList.remove("hide");
    });

    device.on("error", function (error) {
      log("Twilio.Device Error: " + error.message);
    });

    device.on("incoming", handleIncomingCall);

    device.audio.on("deviceChange", updateAllAudioDevices.bind(device));

    // Show audio selection UI if it is supported by the browser.
    if (device.audio.isOutputSelectionSupported) {
      audioSelectionDiv.classList.remove("hide");
    }
  }

  async function makeOutgoingCall(phoneNumber, isTestCall = false) {
    testCall = isTestCall;  // set testCall to false for regular calls
    var params = {
      To: phoneNumber,
    };

    if (device) {
      log(`Attempting to call ${params.To} ...`);

      call = await device.connect({ params });

      call.on("accept", updateUIAcceptedOutgoingCall);
      call.on("disconnect", () => {
        updateUIDisconnectedOutgoingCall();
        testCall = false;  // Reset testCall flag
      });
      call.on("cancel", () => {
        updateUIDisconnectedOutgoingCall();
        testCall = false;  // Reset testCall flag
      });
      
      outgoingCallHangupButton.onclick = () => {
        log("Hanging up ...");
        call.disconnect();
      };

      return call;
    } else {
      log("Unable to make call.");
      return null;
    }
  } 

  $(".digit").on('click', function() {
    console.log("Digit clicked");  // Check if the click event is being triggered
    var input = document.getElementById("phone-number");
    var num = ($(this).clone().children().remove().end().text());
    input.value = input.value + num;
    var currentTime = Date.now();
    
    console.log("Call Started:", callStarted);  // Check the value of callStarted
    if(callStarted) { 
      var lapTime = Math.floor((currentTime - lastTime) / 500);
      var lapString = 'w'.repeat(lapTime) + num.trim();

      console.log("Lap Time:", lapTime);  // Log the duration
      console.log("Lap String:", lapString);  // Log the 'w' characters
    
      var dtmf = num.trim();
      dtmf = dtmf.toString();
      console.log("DTMF:", dtmf);  // Check the value of dtmf
      call.sendDigits(dtmf);
      dtmfDigits += lapString;
      console.log("DTMF Digits:", dtmfDigits);  // Check the value of dtmfDigits
      $("#dtmf-display").append(lapString + " ");
      
      // update the dtmf-string textarea and call the convertString function
      document.getElementById("dtmf-string").value = dtmfDigits;
      console.log("Updated Last Time:", lastTime);  // Check the updated value of lastTime
      convertString();
    
    } else {
      phoneNumber += num.trim();
      input.value = phoneNumber;
    }
    
    lastTime = currentTime;
    console.log("lastTime updated:", lastTime);
  });
  
  window.addEventListener('DOMContentLoaded', (event) => {
    document.querySelectorAll('textarea.autoExpand').forEach(function(element) {
      element.addEventListener('input', autoExpand);
    });
  });
  
  
  function autoExpand(event) {
    this.style.height = 'inherit';
    this.style.height = (this.scrollHeight) + 'px';
  }
  

  function updateUIAcceptedOutgoingCall(call) {
    log("Call in progress ...");
    startTimer(); // Start the timer when the call is accepted
    callButton.disabled = true;
    outgoingCallHangupButton.classList.remove("hide");
    volumeIndicators.classList.remove("hide");
    bindVolumeIndicators(call);
    callStarted = true;
    lastTime = Date.now();  // Initialize lastTime when the call starts
    callStartTime = new Date();  // Start the timer

    // Start converting DTMF string to steps once the call is accepted
    console.log("About to call convertString");  // Check when convertString is about to be called
    convertString();
  }


  function updateUIDisconnectedOutgoingCall() {
    log("Call disconnected.");
    stopTimer(); // Stop the timer when the call is disconnected
    callButton.disabled = false;
    outgoingCallHangupButton.classList.add("hide");
    callButton.classList.remove("hide");
    volumeIndicators.classList.add("hide");
    callStarted=false;
    let callEndTime = new Date();  // Get the time when the call ended
    let callDuration = (callEndTime - callStartTime) / 1000;  // Calculate the duration in seconds
    
    if (!testCall) {  // Only record the call if it's not a test call
      recordCallHistory(phoneNumberInput.value, dtmfDigits, callDuration);  // Record the call
    }
    
    dtmfDigits = "";
  
    // Clear the DTMF display
    $("#dtmf-display").empty();
  }

  // test twiml by placing a call using the twiml
  function playDtmfString(dtmfString, call) {
    let i = 0;

    function next() {
        if (i < dtmfString.length && callActive) {
            let c = dtmfString[i++];
            if (c === 'w') {
                // wait half a second before sending the next tone
                setTimeout(next, 500);
            } else {
                // send DTMF tone and then wait half a second before sending the next one
                call.sendDigits(c);
                setTimeout(next, 500);
            }
        }
    }

    next();
  }

  call.on("accept", () => {
    // when the call is accepted, play the DTMF string
    playDtmfString(dtmfDigits, call);
  });

  $("#testButton").on('click', function() {
    // Retrieve phone number and DTMF string from button data
    var phoneNumber = $(this).data("phoneNumber");
    var dtmfString = $(this).data("dtmfString");
  
    console.log(phoneNumber, dtmfString);  // Print these for debugging
  
    makeOutgoingCall(phoneNumber).then(call => {
      if (call) {
        call.on("accept", () => {
          playDtmfString(dtmfString, call);
        });
  
        call.on("disconnect", () => {
          // Stop the timer when the test call is disconnected
          stopTimer();
          testCall = false;  // Reset testCall flag
        });
      }
    });
  });



  // HANDLE INCOMING CALL

  function handleIncomingCall(call) {
    log(`Incoming call from ${call.parameters.From}`);

    //show incoming call div and incoming phone number
    incomingCallDiv.classList.remove("hide");
    incomingPhoneNumberEl.innerHTML = call.parameters.From;

    //add event listeners for Accept, Reject, and Hangup buttons
    incomingCallAcceptButton.onclick = () => {
      acceptIncomingCall(call);
    };

    incomingCallRejectButton.onclick = () => {
      rejectIncomingCall(call);
    };

    incomingCallHangupButton.onclick = () => {
      hangupIncomingCall(call);
    };

    // add event listener to call object
    call.on("cancel", handleDisconnectedIncomingCall);
    call.on("disconnect", handleDisconnectedIncomingCall);
    call.on("reject", handleDisconnectedIncomingCall);
  }

  // ACCEPT INCOMING CALL

  function acceptIncomingCall(call) {
    call.accept();

    //update UI
    log("Accepted incoming call.");
    incomingCallAcceptButton.classList.add("hide");
    incomingCallRejectButton.classList.add("hide");
    incomingCallHangupButton.classList.remove("hide");
    callStarted=true; 
    callStartTime = new Date();  // Start the timer when the call is accepted
  }

  // REJECT INCOMING CALL

  function rejectIncomingCall(call) {
    call.reject();
    log("Rejected incoming call");
    resetIncomingCallUI();
  }

  // HANG UP INCOMING CALL

  function hangupIncomingCall(call) {
    call.disconnect();
    log("Hanging up incoming call");
    resetIncomingCallUI();
  }

  // HANDLE CANCELLED INCOMING CALL

  function handleDisconnectedIncomingCall() {
    log("Incoming call ended.");
    resetIncomingCallUI();
    let callEndTime = new Date();  // Get the time when the call ended
    let callDuration = (callEndTime - callStartTime) / 1000;  // Calculate the duration in seconds
    recordCallHistory(incomingPhoneNumberEl.innerText, dtmfDigits, callDuration);  // Record the call
  }

  // MISC USER INTERFACE

  // Activity log
  function log(message) {
    logDiv.innerHTML += `<p class="log-entry">&gt;&nbsp; ${message} </p>`;
    logDiv.scrollTop = logDiv.scrollHeight;
  }

  function setClientNameUI(clientName) {
    var div = document.getElementById("client-name");
    div.innerHTML = `Your client name: <strong>${clientName}</strong>`;
  }

  function resetIncomingCallUI() {
    incomingPhoneNumberEl.innerHTML = "";
    incomingCallAcceptButton.classList.remove("hide");
    incomingCallRejectButton.classList.remove("hide");
    incomingCallHangupButton.classList.add("hide");
    incomingCallDiv.classList.add("hide");
  }

  // AUDIO CONTROLS

  async function getAudioDevices() {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    updateAllAudioDevices.bind(device);
  }

  function updateAllAudioDevices() {
    if (device) {
      updateDevices(speakerDevices, device.audio.speakerDevices.get());
      updateDevices(ringtoneDevices, device.audio.ringtoneDevices.get());
    }
  }

  function updateOutputDevice() {
    const selectedDevices = Array.from(speakerDevices.children)
      .filter((node) => node.selected)
      .map((node) => node.getAttribute("data-id"));

    device.audio.speakerDevices.set(selectedDevices);
  }

  function updateRingtoneDevice() {
    const selectedDevices = Array.from(ringtoneDevices.children)
      .filter((node) => node.selected)
      .map((node) => node.getAttribute("data-id"));

    device.audio.ringtoneDevices.set(selectedDevices);
  }

  function bindVolumeIndicators(call) {
    call.on("volume", function (inputVolume, outputVolume) {
      var inputColor = "red";
      if (inputVolume < 0.5) {
        inputColor = "green";
      } else if (inputVolume < 0.75) {
        inputColor = "yellow";
      }

      inputVolumeBar.style.width = Math.floor(inputVolume * 300) + "px";
      inputVolumeBar.style.background = inputColor;

      var outputColor = "red";
      if (outputVolume < 0.5) {
        outputColor = "green";
      } else if (outputVolume < 0.75) {
        outputColor = "yellow";
      }

      outputVolumeBar.style.width = Math.floor(outputVolume * 300) + "px";
      outputVolumeBar.style.background = outputColor;
    });
  }

  // Update the available ringtone and speaker devices
  function updateDevices(selectEl, selectedDevices) {
    selectEl.innerHTML = "";

    device.audio.availableOutputDevices.forEach(function (device, id) {
      var isActive = selectedDevices.size === 0 && id === "default";
      selectedDevices.forEach(function (device) {
        if (device.deviceId === id) {
          isActive = true;
        }
      });

      var option = document.createElement("option");
      option.label = device.label;
      option.setAttribute("data-id", id);
      if (isActive) {
        option.setAttribute("selected", "selected");
      }
      selectEl.appendChild(option);
    });
  }

  function recordCallHistory(phoneNumber, dtmfDigits, callDuration) {
    // Only save the first 10 digits of the phone number
    phoneNumber = phoneNumber.slice(0, 10);

    // Skip recording if this is a test call
    if (testCall) {
      return;
    }
    // Get the current date.
    var date = new Date();
    
    // Format the date as a string: YYYY/MM/DD.
    var dateString = date.getFullYear() + '/' + (date.getMonth()+1) + '/' + date.getDate();
  
    // Get the existing call history from local storage.
    var callHistory = JSON.parse(localStorage.getItem('callHistory')) || [];
  
    // Add the new call to the history.
    callHistory.push({ date: dateString, phoneNumber: phoneNumber, dtmfDigits: dtmfDigits, callDuration: callDuration });
  
    // Save the updated history back to local storage.
    localStorage.setItem('callHistory', JSON.stringify(callHistory));
  
    // Update the call history display.
    displayCallHistory();
  }
  
  function displayCallHistory() {
    // Skip recording if this is a test call
    if (testCall) {
      return;
    }
    // Get the call history from local storage.
    var callHistory = JSON.parse(localStorage.getItem('callHistory')) || [];
  
    // Get the call history div.
    var callHistoryDiv = document.getElementById('call-history');
  
    // Clear the current content.
    callHistoryDiv.innerHTML = '';
  
    // Add each call to the call history div.
    callHistory.forEach(function(call) {
      var callEntry = document.createElement('div');
      callEntry.innerHTML = `<p>Date: ${call.date}, Phone Number: ${call.phoneNumber}, DTMF: ${call.dtmfDigits}, Duration: ${call.callDuration} seconds</p>`;
  
      var testButton = document.createElement('button');
      testButton.textContent = "Test this string";
      testButton.setAttribute("data-phone-number", call.phoneNumber);
      testButton.setAttribute("data-dtmf-string", call.dtmfDigits);
      testButton.classList.add("test-call-button");
      callEntry.appendChild(testButton);
  
      var addToContactsButton = document.createElement('button');
      addToContactsButton.textContent = "Add to Contacts";
      addToContactsButton.setAttribute("data-phone-number", call.phoneNumber);
      addToContactsButton.setAttribute("data-dtmf-string", call.dtmfDigits);
      addToContactsButton.classList.add("add-to-contacts-button");
      callEntry.appendChild(addToContactsButton);

      // Add a "Delete" button
      var deleteButton = document.createElement('button');
      deleteButton.classList.add("delete-call-button");

      // Create a trash can icon and add it to the button
      var trashIcon = document.createElement('i');
      trashIcon.classList.add("fas", "fa-trash");
      trashIcon.style.color = "red";
      deleteButton.appendChild(trashIcon);

      callEntry.appendChild(deleteButton);
  
      callHistoryDiv.appendChild(callEntry);
  
      testButton.addEventListener('click', function(e) {
        e.preventDefault();
      
        // Retrieve phone number and DTMF string from button data
        var phoneNumber = e.target.getAttribute("data-phone-number");
        var dtmfString = e.target.getAttribute("data-dtmf-string");
      
        console.log("phoneNumber:", phoneNumber);  // Log the phone number
        console.log("dtmfString:", dtmfString);  // Log the DTMF string
      
        makeOutgoingCall(phoneNumber, true).then(call => {  // Pass true for isTestCall
          console.log("makeOutgoingCall called");  // Check if makeOutgoingCall is called
        
          call.on("accept", () => {
            console.log("Call accepted");  // Check if the call is accepted
        
            // Mark the call as active
            callActive = true;
            playDtmfString(dtmfString, call);
        
            // Update the UI
            updateUIAcceptedOutgoingCall(call);
          });
        
          call.on("disconnect", () => {
            console.log("Call disconnected");  // Check if the call is disconnected
          
            // Mark the call as not active
            callActive = false;
            testCall = false;  // Reset testCall flag
          
            // Stop the timer
            stopTimer();
          
            // Update the UI
            updateUIDisconnectedOutgoingCall();
          });
        });
      });

                             
  
      // Add an event listener to the "Add to Contacts" button
      addToContactsButton.addEventListener('click', function(e) {
        e.preventDefault();
        
        console.log("Add to Contacts button clicked");  // Debugging statement

        // Retrieve the contact list from the local storage.
        var contactList = JSON.parse(localStorage.getItem('contactList')) || [];
    
        // Retrieve phone number and DTMF string from button data
        var phoneNumber = e.target.getAttribute("data-phone-number");
        var dtmfString = e.target.getAttribute("data-dtmf-string");

        // Log the retrieved values
        console.log('phoneNumber:', phoneNumber);
        console.log('dtmfString:', dtmfString);
    
        // Here you could open a dialog asking for the agency name, or handle it however you wish
        var agencyName = prompt("Please enter the agency name for this contact");
    
        // Add the new contact to the list
        contactList.push({agencyName: agencyName, phoneNumber: phoneNumber, dtmfDigits: dtmfString});
    
        // Save the updated contact list to local storage
        localStorage.setItem('contactList', JSON.stringify(contactList));
    
        // Update the contact list display.
        displayContactList();
      });
        // Add an event listener to the deleteButton
        deleteButton.addEventListener('click', function(e) {
          e.preventDefault();

          // Remove the call entry div from the call history div
          callHistoryDiv.removeChild(callEntry);

          // You would also need to remove this call record from your 'callHistory' array
          const index = callHistory.indexOf(call);
          if (index > -1) {
            callHistory.splice(index, 1);
          }
  
          // Save the updated history back to local storage
          localStorage.setItem('callHistory', JSON.stringify(callHistory));
        });    
    });
  }
  
  function displayContactList() {
    // Load the contact list from local storage.
    let contactList = JSON.parse(localStorage.getItem('contactList')) || [];

    // Get the contact list div.
    let contactListDiv = document.getElementById('contact-list');

    // Clear the current content.
    contactListDiv.innerHTML = '';

    // Add each contact to the contact list div.
    contactList.forEach(function(contact, index) {
        let contactEntry = document.createElement('div');
        contactEntry.classList.add("contact-card"); // Add the contact card class

        let agencyName = document.createElement('h3');
        agencyName.textContent = contact.agencyName;
        contactEntry.appendChild(agencyName);

        let phoneNumber = document.createElement('p');
        phoneNumber.textContent = 'Phone: ' + contact.phoneNumber;
        contactEntry.appendChild(phoneNumber);

        let dtmfDigits = document.createElement('p');
        dtmfDigits.textContent = 'DTMF: ' + contact.dtmfDigits;
        dtmfDigits.classList.add("dtmf-display-class"); // Add the dtmf-display-class to DTMF display
        contactEntry.appendChild(dtmfDigits);

        var testButton = document.createElement('button');
        testButton.textContent = "Test this string";
        testButton.setAttribute("data-phone-number", contact.phoneNumber);
        testButton.setAttribute("data-dtmf-string", contact.dtmfDigits);
        testButton.classList.add("test-call-button");

        // Add an event listener to this testButton
        testButton.addEventListener('click', function(e) {
          e.preventDefault();
  
          // Retrieve phone number and DTMF string from button data
          var phoneNumber = e.target.getAttribute("data-phone-number");
          var dtmfString = e.target.getAttribute("data-dtmf-string");
  
          makeOutgoingCall(phoneNumber).then(call => {
            call.on("accept", () => {
              playDtmfString(dtmfString, call);
            });
          });
        });

        var deleteButton = document.createElement('button');
        deleteButton.textContent = "Delete Contact";
        deleteButton.classList.add("delete-contact-button");

        deleteButton.addEventListener('click', function() {
            // Remove this contact from the contactList.
            contactList.splice(index, 1);

            // Save the updated contact list to local storage.
            localStorage.setItem('contactList', JSON.stringify(contactList));

            // Refresh the contact list display.
            displayContactList();
        });

        contactEntry.appendChild(testButton);
        contactEntry.appendChild(deleteButton);
        contactListDiv.appendChild(contactEntry);
    });
  }

  document.getElementById('add-contact-button').addEventListener('click', function(e) {
      e.preventDefault();

      var agencyName = document.getElementById('agency-name-input').value;
      var phoneNumber = document.getElementById('phone-number-input').value;
      var dtmfString = document.getElementById('dtmf-string-input').value;

      var newContact = {agencyName: agencyName, phoneNumber: phoneNumber, dtmfString: dtmfString};

      // Add the new contact to the list and save it back to local storage.
      contactList.push(newContact); // Use the global contactList
      localStorage.setItem('contactList', JSON.stringify(contactList));

      // Refresh the contact list display.
      displayContactList();
  });


  $("body").on('click', '.test-call-button', function() {
    // Retrieve phone number and DTMF string from button data
    var phoneNumber = $(this).data("phoneNumber");
    var dtmfString = $(this).data("dtmfString");
  
    makeOutgoingCall(phoneNumber).then(call => {
      call.on("accept", () => {
        playDtmfString(dtmfString, call);
      });
    });
  });  

  function startTimer() {
    // reset any time data
     seconds = 0;
     minutes = 0;
     hours = 0;
   
     const callTimerElement = document.getElementById('call-timer'); // Get the timer element from DOM
   
     callTimer = setInterval(() => {
       seconds++;
       if (seconds >= 60) {
         seconds = 0;
         minutes++;
         if (minutes >= 60) {
           minutes = 0;
           hours++;
         }
       }
       let formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
     
       callTimerElement.innerHTML = formattedTime; // Update timer element in DOM
     }, 1000);
   }
 
   function stopTimer() {
     if (callTimer) {
       clearInterval(callTimer); // Stop the timer
       document.getElementById('call-timer').innerHTML = '00:00:00'; // Reset timer in UI
     }
   }

  displayContactList();

});